import React, { useState } from 'react';
import Carousel from 'nuka-carousel';

import { useInterval } from '~/hooks/use-interval';

import styles from '~/components/plans/add-ons/add-ons-page-content.module.scss';

const MobileCarousel = ({
  children,
  isTablet,
  animateOnLoad = true,
  selectedPlanIndex = 0,
  ...otherProps
}) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(selectedPlanIndex);
  const [runAnimation, setRunAnimation] = useState(
    animateOnLoad ? true : false
  );

  useInterval(
    () => {
      if (!runAnimation) {
        return;
      }

      if (activeSlideIndex < children.length - 1) {
        setActiveSlideIndex(activeSlideIndex + 1);
      } else {
        setActiveSlideIndex(children.length - 1);
        setRunAnimation(false);
      }
    },
    runAnimation ? 1200 : null
  );

  return (
    <Carousel
      beforeSlide={(_, toSlide) => setActiveSlideIndex(toSlide)}
      onDragStart={() => setRunAnimation(false)}
      slideIndex={activeSlideIndex}
      scrollMode="page"
      cellSpacing={30}
      cellAlign="center"
      disableEdgeSwiping // <- This seems to break swiping on desktop...
      slidesToShow={isTablet ? 2 : 1.25}
      renderCenterLeftControls={() => null}
      renderCenterRightControls={() => null}
      dragThreshold={0.3}
      defaultControlsConfig={{
        pagingDotsContainerClassName: `${styles.dotsContainer}`,
        pagingDotsClassName: `${styles.dots}`,
      }}
      style={{ padding: '2rem 0 0.6rem' }}
      {...otherProps}
    >
      {children}
    </Carousel>
  );
};

export default MobileCarousel;
